export const pageController = {
  default: {
    nav: {
      title: true,
      img: false
    },
    home: {
      logo: true
    }
  },
  1: {},
  2: {
    nav: {
      title: false,
      img: true
    },
    home: {
      logo: false
    }
  }
}
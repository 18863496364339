var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav",style:({
  'width':  _vm.$nav.width,
  'background-color': _vm.$nav.bgColor,
  'color': _vm.$nav.color,
  'font-size': _vm.$nav.fontSize
})},[(_vm.$pageController.nav.title)?_c('h2',[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.$pageController.nav.img)?_c('img',{staticClass:"titleImg",attrs:{"src":'/img/'+ _vm.webName +'/logo.png'}}):_vm._e(),_c('ul',_vm._l((_vm.navList),function(item,index){return _c('li',{key:'nav_' + index,class:{'active': _vm.nav === index},style:({
        'color': _vm.nav === index ? _vm.$nav.activeColor : ''
      }),on:{"click":function($event){return _vm.$router.push('/' + index)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="nav" :style="{
    'width':  $nav.width,
    'background-color': $nav.bgColor,
    'color': $nav.color,
    'font-size': $nav.fontSize
  }">
    <h2 v-if="$pageController.nav.title">{{ title }}</h2>
    <img v-if="$pageController.nav.img" :src="'/img/'+ webName +'/logo.png'" class="titleImg">
    <ul>
      <li
        :class="{'active': nav === index}"
        :style="{
          'color': nav === index ? $nav.activeColor : ''
        }"
        v-for="(item, index) in navList"
        :key="'nav_' + index"
        @click="$router.push('/' + index)">
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
export default {
  name: 'GNav',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'title',
      'navList',
      'nav',
      'webName'
    ]),
  }
}
</script>
<style scoped>
.nav {
  width: 250px;
  height: 100%;
  background: rgba(33,33,33,1);
  text-align: left;
  padding-left: 48px;
  font-size: 19px;
  color: #fff;
  position: fixed;
  z-index: 2;
  left: 0;
}
h2 {
  padding: 50px 0 30px;
  width: 180px;
  font-weight: normal;
  font-size: 34px;
  line-height: 40px;
}
img {
  width: 130px;
  padding-top: 30px;
}
li {
  line-height: 40px;
  cursor: pointer;
}
li.active {
  color: #6b47b3;
}
</style>
<template>
  <div class="gFooter">
    {{ copyright }}
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'GFooter',
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters([
      'copyright'
    ]),
  },
}
</script>
<style scoped>
.gFooter {
  font-size: 12px;
  text-align: left;
  padding: 0 0 30px 310px;
}
</style>